.Medication_photo {
  margin-top: 5vh;
  max-width: 80%;
  max-height: 60vh;
  border-radius: 4%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  object-fit: cover;
  min-height: 30vh;
}

/* Responsive CSS */
@media screen and (max-width: 600px) {
  .Medication_photo {
    object-fit: fill;
  }
}
