.Navbar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.divider_sections {
  display: flex;
  margin: 1rem;
  justify-content: space-around;
}

.iconStyling {
  color: #3388ff;
  font-weight: bold;
  padding-right: 0.5rem;
}

.mobileNavBarContainer {
  padding-top: 0.4rem;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  align-items: center;
  width: 100%;
  border-radius: 25px;
}
