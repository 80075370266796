.post_container {
  margin: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.underline {
  background: hsl(205, 78%, 60%);
  height: 0.25rem;
  width: 5rem;
  margin: 0.5rem auto;
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 2rem !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
}

.italize {
  font-style: italic;
}

.important {
  color: hsl(205, 78%, 60%);
  font-weight: bold;
  font-style: italic;
  padding: 0 6px;
}

.center {
  margin: 0.5rem auto;
}

.whiteSpaceContent {
  white-space: nowrap;
}

.leftContainer {
  display: flex;
  justify-content: center;
}

.marginLeftRight {
  margin: 0 3rem;
}
/* Responsive CSS */
@media screen and (max-width: 992px) {
  .post_container {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 1.6rem !important;
  }
  .post_container {
    margin: 1rem;
  }
  .displayflex {
    display: flex;
  }
}
