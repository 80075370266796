* {
  margin: 0;
}

.HomePage {
  margin: 2rem;
}

.banner_container {
  display: flex;
  justify-content: center;
  margin-left: 4rem;
}

.doctor_img {
  max-width: 100%;
  height: auto;
  margin-left: 5rem;
  object-fit: cover;
}

.text_container {
  height: 50vh;
  background-color: grey;
}

.marginTop {
  margin-top: 2rem;
}
.blob {
  border-radius: 39% 61% 70% 30% / 30% 72% 28% 70%;
  max-width: 80%;
  z-index: 1;
}

.underlineHome {
  background: hsl(205, 78%, 60%);
  height: 0.25rem;
  width: 5rem;
  margin: 0.5rem 10% 1rem;
}

.hoursBackground {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  min-width: 50%;
  border-radius: 15px;
  padding: 0.5rem;
  padding-left: 1.5rem;
  color: black;
  margin-top: 10%;
}

/* Responsive CSS */
@media screen and (max-width: 1000px) {
  .banner_container {
    flex-direction: column;
    margin: 2rem;
  }
  .test {
    background-position: 40%;
  }
}
